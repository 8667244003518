body {
  margin: 0;
  padding: 0;

  /* Celo Primary */
  --celo-green: #84c3bb;
  --celo-gold: #326f6b;
  --celo-dark: #2e3338;
  --celo-white: #ffffff;

  /* Celo Accent */
  --celo-accent-violet: #bf97ff;
  --celo-accent-red: #ff6241;
  --celo-accent-cyan: #73ddff;
  --celo-accent-blue: #3488ec;

  /* Celo Gray */
  --celo-gray-heavy: #abadaf;
  --celo-gray: #dddddd;
  --celo-gray-light: #edeeef;
  --celo-gray-faint: #f8f9f9;

  /* Celo Background */
  --celo-canvas-white: #ffffff;
  --celo-canvas-faint-gray: #f8f9f9;
  --celo-canvas-faint-gold: #fef2d6;
  --celo-canvas-dark: #2e3338;

  /* Custom */
  --keyko-light-background: #fbfafc;
  --keyko-dark-background: #2f3337;
  --terminal-green: #fff8ec;

  /* CGA */
  --cga-black: #000000;
  --cga-blue: #0000aa;
  --cga-green: #00aa00;
  --cga-cyan: #00aaaa;
  --cga-red: #aa0000;
  --cga-magenta: #aa00aa;
  --cga-brown: #aa5500;
  --cga-light-gray: #aaaaaa;
  --cga-dark-gray: #f1f1f1;
  --cga-light-blue: #020202;
  --cga-light-green: #55ff55;
  --cga-light-cyan: #55ffff;
  --cga-light-red: #ff5555;
  --cga-light-magenta: #ff55ff;
  --cga-yellow: #ffff55;
  --cga-white: #ffffff;
}
