html,
body {
  height: 100%;
}

body {
  background-color: var(--cga-dark-gray);
}

.App {
  text-align: center;
  font-family: "kanit, ibm-plex-sans";
  margin: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
  color: var(--cga-black);
}

.App-content {
  flex: 1 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 10px;
}

.centered {
  align-items: center;
}

a {
  color: var(--cga-light-cyan);
}
a:hover {
  color: var(--cga-blue);
}

.page-header {
  align-items: center;
}

.celoIcons {
  max-width: 100px;
}

.header-logo {
  display: flex;
  max-width: 250px;
  max-height: 80%;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--terminal-green);
}

.table {
  word-wrap: none;
}
